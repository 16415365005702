@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

:root {
  --black: #1c1e21;
  --white: #ffffff;
  --tw-color1: #a855f7;
  --tw-color1-hover: #9333ea;
}

body {
  background: linear-gradient(to bottom, #0000cc 0%, #ff00ff 100%);
  font-family: "Inter", sans-serif;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.logo {
  width: max-content;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 6px;
}

.logo > svg {
  height: 24px;
}

.container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}

.btn {
  cursor: pointer;
  display: inline-flex;
  appearance: none;
  align-items: center;
  -webkit-box-align: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: 600;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  min-width: 3rem;
  font-size: 1rem;
  background: #e5e5ea;
  background-image: linear-gradient(to left, #cc25b3 0%, #418dff 101.52%);
  color: #fff;
  width: 180px;
  text-align: center;
  border-radius: 9999px;
  border-style: none;
}

.btn:hover {
  opacity: 0.8;
}

.btn:disabled {
  opacity: 0.5;
}

.address {
  font-size: 1.5rem;
  margin-bottom: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  font-size: 2rem;
}
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.column {
  float: left;
  width: 33.3%;
  margin-bottom: 16px;
  padding: 0 8px;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 0 16px;
}

.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}
